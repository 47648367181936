import { EMOTION_PICS, TELEGRAM } from "../../util/constants";
import { sendMetrics } from "../../util/metrics";
import { startCheckup } from "../../util/helpers";
import { getISODate } from "../../util/dates";

/**
 * Рендер картинки по настроению
 *
 * @param {string} mood - настроение дня
 * @param {boolean} isToday - сегодня
 * @param {Function} onClickFunction - функция чекапа для бота
 * @param {string} [apiToken] - токен
 * @param {string} [shareToken] - токен
 * @returns {JSX.Element}
 */
export const renderImage = (
  mood: string,
  isToday: boolean,
  onClickFunction: Function,
  apiToken: string,
  shareToken: string
): JSX.Element => {
  if (EMOTION_PICS[mood]) {
    return <img src={EMOTION_PICS[mood]} alt="mood" />;
  }
  if (isToday) {
    return (
      <>
        {!shareToken ? (
          <button
            onClick={() => {
              onClickFunction(apiToken);
            }}
          >
            <div className="today-svg">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="18"
                  cy="18"
                  r="17.5"
                  fill="var(--tg-theme-bg-color, #eeeef8)"
                  stroke="var(--tg-theme-button-color, #7868e6)"
                />
                <line
                  x1="18.5"
                  y1="10"
                  x2="18.5"
                  y2="26"
                  stroke="var(--tg-theme-button-color, #7868e6)"
                />
                <line
                  x1="10"
                  y1="17.5"
                  x2="26"
                  y2="17.5"
                  stroke="var(--tg-theme-button-color, #7868e6)"
                />
              </svg>
            </div>
          </button>
        ) : (
          <div className="table-cell__img" />
        )}
      </>
    );
  }

  return <div className="table-cell__img" />;
};

/**
 * Рендер дополнительной информации
 *
 * @param {number} checkNote
 * @param {any} baseImg
 * @param {any} [secondaryImg=null]
 * @param {boolean} [isNumericValue=false]
 * @returns {*}
 */
export const dayInfoHandler = (
  checkNote: number,
  baseImg: any,
  secondaryImg = null,
  isNumericValue = false
) => {
  if (!checkNote) return;
  if (isNumericValue && checkNote === 1) {
    return <img src={secondaryImg} alt="dayInfo" />;
  }
  return <img src={baseImg} alt="dayInfo" />;
};

export const startCheckupFromMainPage = function (apiToken: string) {
  if (startCheckupFromMainPage.cantClick) return;
  startCheckupFromMainPage.cantClick = true;
  sendMetrics("reachGoal", "click-start-checkup-main-page-btn");
  startCheckup(apiToken, getISODate(new Date()));
  setTimeout(() => TELEGRAM.close(), 0);
  setTimeout(() => (startCheckupFromMainPage.cantClick = false), 500);
} as {
  (apiToken: string): void;
  cantClick?: boolean;
};
