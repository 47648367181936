/**
 * Преобразование заголовка кастомного вопроса по гендеру пользователя
 *
 * @param {string} question
 * @param {string} language
 * @param {("male" | "female")} [gender="male"]
 * @returns {*}
 */
export const parseQuestion = (
  question: string,
  language: string,
  gender: "male" | "female" = "male"
) => {
  if (!gender) gender = "male";

  try {
    // Преобразование одинарных кавычек в двойные для валидного JSON
    const formattedQuestion = question.replace(/'/g, '"');
    const parsedQuestion = JSON.parse(formattedQuestion);

    // Проверяем, есть ли нужный язык и пол в объекте
    if (
      typeof parsedQuestion === "object" &&
      parsedQuestion[language]?.[gender]
    ) {
      return parsedQuestion[language][gender];
    }
  } catch (error) {
    // Если ошибка, возвращаем исходное значение question
  }

  return question; // Возвращаем оригинальную строку, если парсинг не удался
};
