/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios from "axios";
export var ContentType;
(function (ContentType) {
  ContentType["Json"] = "application/json";
  ContentType["FormData"] = "multipart/form-data";
  ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
  ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
export class HttpClient {
  instance;
  securityData = null;
  securityWorker;
  secure;
  format;
  constructor({ securityWorker, secure, format, ...axiosConfig } = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }
  setSecurityData = (data) => {
    this.securityData = data;
  };
  mergeRequestParams(params1, params2) {
    const method = params1.method || (params2 && params2.method);
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase()]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }
  stringifyFormItem(formItem) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }
  createFormData(input) {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent = property instanceof Array ? property : [property];
      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }
      return formData;
    }, new FormData());
  }
  request = async ({ secure, path, type, query, format, body, ...params }) => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;
    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === "object"
    ) {
      body = this.createFormData(body);
    }
    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== "string"
    ) {
      body = JSON.stringify(body);
    }
    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response);
  };
}
/**
 * @title FastAPI
 * @version 0.1.0
 */
export class ApiClient extends HttpClient {
  getCheckupsForMonth = {
    /**
     * No description
     *
     * @name GetMonthCheckupsGetCheckupsForMonthGet
     * @summary Get Month Checkups
     * @request GET:/get_checkups_for_month
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getMonthCheckupsGetCheckupsForMonthGet: (query, params = {}) =>
      this.request({
        path: `/get_checkups_for_month`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  getDaySummary = {
    /**
     * No description
     *
     * @name GetDaySummaryGetDaySummaryGet
     * @summary Get Day Summary
     * @request GET:/get_day_summary
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getDaySummaryGetDaySummaryGet: (query, params = {}) =>
      this.request({
        path: `/get_day_summary`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  addNote = {
    /**
     * No description
     *
     * @name AddNoteAddNotePost
     * @summary Add Note
     * @request POST:/add_note
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    addNoteAddNotePost: (query, params = {}) =>
      this.request({
        path: `/add_note`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  startCheckup = {
    /**
     * No description
     *
     * @name StartCheckupStartCheckupPost
     * @summary Start Checkup
     * @request POST:/start_checkup
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    startCheckupStartCheckupPost: (query, params = {}) =>
      this.request({
        path: `/start_checkup`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  logWebappAction = {
    /**
     * No description
     *
     * @name LogWebappActionLogWebappActionPost
     * @summary Log Webapp Action
     * @request POST:/log_webapp_action
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    logWebappActionLogWebappActionPost: (query, data, params = {}) =>
      this.request({
        path: `/log_webapp_action`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  openDiarySettings = {
    /**
     * No description
     *
     * @name OpenDiarySettingsPostOpenDiarySettingsPost
     * @summary Open Diary Settings Post
     * @request POST:/open_diary_settings
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    openDiarySettingsPostOpenDiarySettingsPost: (query, params = {}) =>
      this.request({
        path: `/open_diary_settings`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  getShareToken = {
    /**
     * No description
     *
     * @name GetShareLinkGetShareTokenGet
     * @summary Get Share Link
     * @request GET:/get_share_token
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getShareLinkGetShareTokenGet: (query, params = {}) =>
      this.request({
        path: `/get_share_token`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  updateShareAccess = {
    /**
     * No description
     *
     * @name UpdateShareAccessCbUpdateShareAccessGet
     * @summary Update Share Access Cb
     * @request GET:/update_share_access
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updateShareAccessCbUpdateShareAccessGet: (query, params = {}) =>
      this.request({
        path: `/update_share_access`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  revokeShare = {
    /**
     * No description
     *
     * @name RevokeShareRevokeShareGet
     * @summary Revoke Share
     * @request GET:/revoke_share
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    revokeShareRevokeShareGet: (query, params = {}) =>
      this.request({
        path: `/revoke_share`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  getDiaryViewers = {
    /**
     * No description
     *
     * @name GetDiaryViewersGetDiaryViewersGet
     * @summary Get Diary Viewers
     * @request GET:/get_diary_viewers
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getDiaryViewersGetDiaryViewersGet: (query, params = {}) =>
      this.request({
        path: `/get_diary_viewers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  getProfilePic = {
    /**
     * No description
     *
     * @name GetProfilePicGetProfilePicGet
     * @summary Get Profile Pic
     * @request GET:/get_profile_pic
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getProfilePicGetProfilePicGet: (query, params = {}) =>
      this.request({
        path: `/get_profile_pic`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  getViewableDiaries = {
    /**
     * No description
     *
     * @name GetViewableDiariesGetViewableDiariesGet
     * @summary Get Viewable Diaries
     * @request GET:/get_viewable_diaries
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getViewableDiariesGetViewableDiariesGet: (query, params = {}) =>
      this.request({
        path: `/get_viewable_diaries`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  checkups = {
    /**
     * No description
     *
     * @name DeleteCheckupCheckupsCheckupIdDelete
     * @summary Delete Checkup
     * @request DELETE:/checkups/{checkup_id}
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    deleteCheckupCheckupsCheckupIdDelete: (checkupId, query, params = {}) =>
      this.request({
        path: `/checkups/${checkupId}`,
        method: "DELETE",
        query: query,
        format: "json",
        ...params,
      }),
  };
  moodData = {
    /**
     * @description Get mood data for graph
     *
     * @tags Graph
     * @name GetMoodDataMoodDataGet
     * @summary Get Mood Data
     * @request GET:/mood_data
     * @response `200` `MoodGraphResponse` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getMoodDataMoodDataGet: (query, params = {}) =>
      this.request({
        path: `/mood_data`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  daysFactors = {
    /**
     * No description
     *
     * @tags Graph
     * @name GetSourceEmotionCorrelationDaysFactorsGet
     * @summary Get Source Emotion Correlation
     * @request GET:/days_factors
     * @response `200` `(SourceEmotionCorrelationResponse)[]` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getSourceEmotionCorrelationDaysFactorsGet: (query, params = {}) =>
      this.request({
        path: `/days_factors`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  emotionsStats = {
    /**
     * No description
     *
     * @tags Graph
     * @name GetEmotionSourceCorrelationEmotionsStatsGet
     * @summary Get Emotion Source Correlation
     * @request GET:/emotions_stats
     * @response `200` `(EmotionSourceCorrelationResponse)[]` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getEmotionSourceCorrelationEmotionsStatsGet: (query, params = {}) =>
      this.request({
        path: `/emotions_stats`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  customQuestions = {
    /**
     * No description
     *
     * @tags Graph
     * @name GetCheckupQuestionsCustomQuestionsGet
     * @summary Get Checkup Questions
     * @request GET:/custom_questions
     * @response `200` `CheckupQuestionResponse` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCheckupQuestionsCustomQuestionsGet: (query, params = {}) =>
      this.request({
        path: `/custom_questions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  openQuestionsMenu = {
    /**
     * No description
     *
     * @tags Graph
     * @name SentQuestionsMenuOpenQuestionsMenuPost
     * @summary Sent Questions Menu
     * @request POST:/open_questions_menu
     * @response `201` `OpenMenuResponse` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    sentQuestionsMenuOpenQuestionsMenuPost: (query, params = {}) =>
      this.request({
        path: `/open_questions_menu`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  openFriendsMenu = {
    /**
     * No description
     *
     * @tags Graph
     * @name SentFriendsMenuOpenFriendsMenuPost
     * @summary Sent Friends Menu
     * @request POST:/open_friends_menu
     * @response `201` `OpenMenuResponse` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    sentFriendsMenuOpenFriendsMenuPost: (query, params = {}) =>
      this.request({
        path: `/open_friends_menu`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  openMainMenu = {
    /**
     * Открывает главное меню в боте
     *
     * @tags Webapp
     * @name OnWebappEvent
     * @summary Sent Main Menu
     * @request POST:/on_webapp_event
     * @response `201` `OpenMenuResponse` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    onWebappEvent: (query, data, params = {}) =>
      this.request({
        path: `/on_webapp_event`,
        method: "POST",
        query: query,
        body: data,
        format: "json",
        ...params,
      }),
  };
  payments = {
    /**
     * No description
     *
     * @tags payments
     * @name CreateInvoicePaymentsPost
     * @summary Create Invoice
     * @request POST:/payments
     * @response `201` `CreateInvoiceResponse` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    createInvoicePaymentsPost: (data, params = {}) =>
      this.request({
        path: `/payments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  subjects = {
    /**
     * No description
     *
     * @tags advice, web
     * @name GetAllSubjectsSubjectsGet
     * @summary Get All Subjects
     * @request GET:/subjects
     * @response `200` `(SubjectResponse)[]` Successful Response
     */
    getAllSubjectsSubjectsGet: (params = {}) =>
      this.request({
        path: `/subjects`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  advices = {
    /**
     * @description Get advices from database. Args: no_answers (bool, optional): Get advices without answers. Defaults to False. Inactive if random=True. subject (str, optional): Get advices by subject. random (bool, optional): Get random advices. Defaults to False. offset (int, optional): Offset for pagination. Defaults to 0. Inactive if random=True. limit (int, optional): Limit for pagination. Defaults to 10. Raises: HTTPException: If limit > 100 Returns: list[QuestionResponse]: List of advices
     *
     * @tags advice, web
     * @name GetAdvicesAdvicesGet
     * @summary Get Advices
     * @request GET:/advices
     * @response `200` `(QuestionResponse)[]` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getAdvicesAdvicesGet: (query, params = {}) =>
      this.request({
        path: `/advices`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags advice, web
     * @name GetAdviceAdvicesAdviceSlugGet
     * @summary Get Advice
     * @request GET:/advices/{advice_slug}
     * @response `200` `QuestionWithAnswersResponse` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getAdviceAdvicesAdviceSlugGet: (adviceSlug, params = {}) =>
      this.request({
        path: `/advices/${adviceSlug}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  totalAdvices = {
    /**
     * @description Get advices from database. Args: no_answers (bool, optional): Get advices without answers. Defaults to False. Inactive if random=True. subject (str, optional): Get advices by subject.s Raises: HTTPException: If limit > 100 Returns: list[QuestionResponse]: List of advices
     *
     * @tags advice, web
     * @name GetAdvicesTotalAdvicesGet
     * @summary Get Advices
     * @request GET:/total_advices
     * @response `200` `TotalResponse` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getAdvicesTotalAdvicesGet: (query, params = {}) =>
      this.request({
        path: `/total_advices`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  yearlyReflection = {
    /**
     * @description Get Yearly Reflection
     *
     * @name getYearlyReflection
     * @summary Получение всех итогов года
     * @request GET:/get_yearly_reflection
     * @response `200` `TotalResponse` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getYearlyReflection: (query, params = {}) =>
      this.request({
        path: `/get_yearly_reflection`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    updateYearlyReflection: (query, data, params = {}) =>
      this.request({
        path: `/update_yearly_reflection`,
        method: "POST",
        query: query,
        body: data,
        format: "json",
        ...params,
      }),
    generateYearlyFeedback: (query, params = {}) =>
      this.request({
        path: `/generate_yearly_feedback`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
    purchaseSubscription: (query, params = {}) =>
      this.request({
        path: `/purchase_subscription`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
    prepareMessage: (query, params = {}) =>
      this.request({
        path: `/prepare_message_yearly_feedback`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
