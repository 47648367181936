import { useCallback, useRef, useState } from "react";
import styles from "./resultMonth.module.scss";
import { useDispatch } from "react-redux";
import { updateYearlyReflectionMonth } from "../../store/slices/yearlyReflection";
import { AppDispatch } from "../../store";
import { debounce } from "../../util/helpers";
import Translator from "../../resources/localization/translator";
import useUserParamsStore from "../../stores/useUserParamsStore";

interface YearlyReflectionMonthProps {
  alias: string;
  name: keyof YearlyReflection;
  value: string;
  placeholder: string;
  alwaysShowTextarea?: boolean;
}

const YearlyReflectionTextarea: React.FC<YearlyReflectionMonthProps> = ({
  alias,
  name,
  value,
  placeholder,
  alwaysShowTextarea = false,
}) => {
  const [isInputVisible, setIsInputVisible] = useState<boolean>(false);
  const [textareaHeight, setTextareaHeight] = useState<string>("148px");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const [localValue, setLocalValue] = useState<string>(value || "");
  const { apiToken, language } = useUserParamsStore();
  const [typing, setTyping] = useState<boolean>(false);

  const debouncedSave = useCallback(
    debounce((value: string) => {
      dispatch(
        updateYearlyReflectionMonth({
          apiToken,
          month: name as keyof YearlyReflection,
          value,
        })
      );
    }, 500),
    [dispatch, alias]
  );

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalValue(e.target.value);
    debouncedSave(e.target.value);
    setTyping(true);
    setTimeout(() => setTyping(false), 1000);

    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Сбрасываем высоту
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        130
      )}px`; // Устанавливаем новую высоту

      // Обновляем высоту родительского контейнера
      const parentElement = textareaRef.current.parentElement;
      if (parentElement) {
        const containerHeight = `${Math.max(
          textareaRef.current.scrollHeight + 18,
          148
        )}px`;
        parentElement.style.maxHeight = containerHeight;
        setTextareaHeight(containerHeight);
      }
    }
  };

  const toggleInputVisibility = () => {
    setIsInputVisible(!isInputVisible);
  };

  return (
    <div className={styles.container}>
      {!alwaysShowTextarea && (
        <button
          onClick={toggleInputVisibility}
          style={{
            background: localValue
              ? "var(--tg-theme-button-color, #7868e6)"
              : "var(--tg-theme-bg-color, #f8f8f8)",
          }}
        >
          <div>
            <div className={styles.svgWrapper}>
              {!typing && (
                <svg
                  style={{
                    transition: "opacity 0.3s ease",
                  }}
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C7.20979 2.25 5.4929 2.96116 4.22703 4.22703C2.96116 5.4929 2.25 7.20979 2.25 9C2.25 10.7902 2.96116 12.5071 4.22703 13.773C5.4929 15.0388 7.20979 15.75 9 15.75ZM8.826 11.73L12.576 7.23L11.424 6.27L8.199 10.1392L6.53025 8.46975L5.46975 9.53025L7.71975 11.7802L8.30025 12.3608L8.826 11.73Z"
                    fill={
                      localValue
                        ? "var(--tg-theme-button-text-color, #f3f3f3)"
                        : "var(--tg-theme-section-separator-color, gray)"
                    }
                  />
                </svg>
              )}
              {typing && (
                <svg
                  className={styles.rotating}
                  width="18"
                  height="18"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.68675 2.2051C7.97823 2.16178 8.27507 2.23456 8.51345 2.40779C8.75184 2.58101 8.91274 2.84086 8.96157 3.13147C9.01039 3.42207 8.94325 3.72024 8.77456 3.96186C8.60588 4.20348 8.34913 4.36927 8.0595 4.4236C6.74923 4.6458 5.55994 5.32476 4.70247 6.3401C3.845 7.35545 3.37472 8.64161 3.375 9.9706C3.375 11.4624 3.96763 12.8932 5.02252 13.9481C6.07742 15.003 7.50816 15.5956 9 15.5956V17.8456C4.65075 17.8456 1.125 14.3206 1.125 9.9706C1.125 6.11185 3.9195 2.8381 7.68675 2.2051Z"
                    fill="url(#paint0_linear_3388_7268)"
                  />
                  <path
                    d="M12.669 4.19404C12.7677 4.08398 12.887 3.99444 13.0203 3.93053C13.1536 3.86662 13.2982 3.82961 13.4458 3.8216C13.5933 3.81359 13.7411 3.83474 13.8805 3.88385C14.0199 3.93296 14.1483 4.00905 14.2582 4.10779C15.0825 4.84582 15.7416 5.74953 16.1925 6.75981C16.6434 7.7701 16.876 8.8642 16.875 9.97054C16.875 14.3205 13.3492 17.8455 9 17.8455V15.5955C10.1386 15.5956 11.2504 15.25 12.1885 14.6046C13.1265 13.9592 13.8466 13.0444 14.2535 11.9809C14.6605 10.9175 14.7351 9.75566 14.4676 8.64891C14.2001 7.54217 13.603 6.54267 12.7552 5.78254C12.5332 5.58332 12.3994 5.30405 12.3832 5.00615C12.367 4.70826 12.4698 4.41614 12.669 4.19404Z"
                    fill="var(--tg-theme-button-text-color, #f3f3f3)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3388_7268"
                      x1="5.0625"
                      y1="2.19287"
                      x2="10"
                      y2="16.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#7868E6" />
                      <stop
                        offset="1"
                        stop-color="var(--tg-theme-button-text-color, #f3f3f3)"
                      />
                    </linearGradient>
                  </defs>
                </svg>
              )}
            </div>
            <p
              style={{
                color: localValue
                  ? "var(--tg-theme-button-text-color, #fff)"
                  : "var(--tg-theme-text-color, #000)",
              }}
            >
              {Translator.translate(alias, language)}
            </p>
          </div>
          <svg
            className={isInputVisible ? styles.svgOpened : styles.svgClosed}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5663 21.9189L17.0308 18.3834L13.4952 21.9189C13.3077 22.1065 13.0533 22.2118 12.7881 22.2118C12.5229 22.2118 12.2686 22.1065 12.081 21.9189C11.8935 21.7314 11.7881 21.4771 11.7881 21.2118C11.7881 20.9466 11.8935 20.6923 12.081 20.5047L15.6165 16.9692L12.081 13.4337C11.8935 13.2461 11.7881 12.9918 11.7881 12.7266C11.7881 12.4613 11.8935 12.207 12.081 12.0194C12.2686 11.8319 12.5229 11.7266 12.7881 11.7266C13.0533 11.7266 13.3077 11.8319 13.4952 12.0194L17.0308 15.555L20.5663 12.0194C20.7538 11.8319 21.0082 11.7266 21.2734 11.7266C21.5386 11.7266 21.793 11.8319 21.9805 12.0194C22.168 12.207 22.2734 12.4613 22.2734 12.7266C22.2734 12.9918 22.168 13.2461 21.9805 13.4337L18.445 16.9692L21.9805 20.5047C22.168 20.6923 22.2734 20.9466 22.2734 21.2118C22.2734 21.4771 22.168 21.7314 21.9805 21.9189C21.793 22.1065 21.5386 22.2118 21.2734 22.2118C21.0082 22.2118 20.7538 22.1065 20.5663 21.9189Z"
              fill={
                localValue
                  ? "var(--tg-theme-button-text-color, #f3f3f3)"
                  : "var(--tg-theme-button-color, gray)"
              }
            />
          </svg>
        </button>
      )}
      <div
        style={{
          maxHeight:
            alwaysShowTextarea || isInputVisible ? textareaHeight : "0px",
          overflow: "hidden",
          transition: "max-height 0.3s ease",
        }}
      >
        <textarea
          ref={textareaRef}
          placeholder={placeholder}
          value={localValue}
          onChange={handleTextareaChange}
          onBlur={(e) => {
            debouncedSave(e.target.value);
          }}
          style={{ margin: alwaysShowTextarea ? "0 0 12px 0" : "" }}
        />
      </div>
    </div>
  );
};

export default YearlyReflectionTextarea;
