import { create } from "zustand";
import { API } from "../../util/constants";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import {
  parseData,
  getFirstCheckupDate,
  getDatesArray,
  getPaginationMonthCount,
} from "../../util/helpers";

type CalendarState = {
  currentDate: Date;
  datesArray: Date[];
  firstCheckupDate: Date;
  datesWithCheckup: Record<string, any>;
  datesWithCheckupLoadingStatus: "loading" | "idle" | "error";
  monthCounterDate: Date;
  prevMonthLoading: boolean;
  newItemsLoading: boolean;
  backToTopVisible: boolean;
  target: HTMLElement | null;
  scrollPosition: number;
  queryToggle: boolean;
  resetDataToggle: boolean;
  fetchDatesWithCheckup: (params: {
    dataFromComponent: Date;
    isFirstLoad?: boolean;
    apiKey: string;
    shareToken?: string;
    resetDataToggle: boolean;
  }) => Promise<void>;
  setScrollPosition: (position: number) => void;
  setQueryToggle: (toggle: boolean) => void;
  toggleBackToTopVisible: (visible: boolean) => void;
  setTarget: (target: HTMLElement | null) => void;
  changeMonthCounterDate: (date: Date) => void;
  changePrevMonthLoading: (loading: boolean) => void;
  addMonth: (date: Date) => void;
  setDatesWithCheckup: (data: Record<string, any>) => void;
  setResetDataToggle: (toggle: boolean) => void;
};

const monthCount = 12;

const useCalendarStore = create<CalendarState>((set, get) => ({
  currentDate: new Date(),
  datesArray: getDatesArray(new Date(), monthCount, false),
  firstCheckupDate: new Date(1970, 1, 1),
  datesWithCheckup: {},
  datesWithCheckupLoadingStatus: "loading",
  monthCounterDate: new Date(),
  prevMonthLoading: false,
  newItemsLoading: false,
  backToTopVisible: false,
  target: null,
  scrollPosition: 0,
  queryToggle: true,
  resetDataToggle: false,

  fetchDatesWithCheckup: async ({
    dataFromComponent,
    isFirstLoad = false,
    apiKey,
    shareToken,
    resetDataToggle,
  }) => {
    const month = getMonth(dataFromComponent);
    const year = getYear(dataFromComponent);
    try {
      const requestParams: {
        month: number;
        year: number;
        api_token: string;
        get_first_checkup: boolean;
        till_today: boolean;
        share_token?: string;
      } = {
        month: month + 1,
        year,
        api_token: apiKey,
        get_first_checkup: true,
        till_today: true,
      };

      if (shareToken) {
        requestParams.share_token = shareToken;
      }

      if (resetDataToggle) {
        set({ datesWithCheckup: {} });
      }

      set({ resetDataToggle: false, datesWithCheckupLoadingStatus: "loading" });

      const response =
        await API.getCheckupsForMonth.getMonthCheckupsGetCheckupsForMonthGet(
          requestParams
        );

      if (!isFirstLoad) {
        get().addMonth(dataFromComponent);
      }

      set({
        datesWithCheckupLoadingStatus: "idle",
        datesWithCheckup: parseData(response.data, get().datesWithCheckup),
        firstCheckupDate: getFirstCheckupDate(response.data),
      });
    } catch (err) {
      set({ datesWithCheckupLoadingStatus: "error" });
    }
  },

  setScrollPosition: (position) => set({ scrollPosition: position }),
  setQueryToggle: (toggle) => set({ queryToggle: toggle }),
  toggleBackToTopVisible: (visible) => set({ backToTopVisible: visible }),
  setTarget: (target) => set({ target }),
  changeMonthCounterDate: (date) =>
    set({ monthCounterDate: date, newItemsLoading: true }),
  changePrevMonthLoading: (loading) => set({ prevMonthLoading: loading }),
  addMonth: (date) => {
    set((state) => ({
      datesArray: [
        ...state.datesArray,
        ...getDatesArray(
          date,
          getPaginationMonthCount(
            state.monthCounterDate,
            state.firstCheckupDate
          )
        ),
      ],
    }));
  },
  setDatesWithCheckup: (data) => set({ datesWithCheckup: data }),
  setResetDataToggle: (toggle) => set({ resetDataToggle: toggle }),
}));

export default useCalendarStore;
