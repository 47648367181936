import * as React from "react";
import "./SharingPage.scss";
import { ReactComponent as ShareIcon } from "../../resources/images/shareIcon.svg";
import { ReactComponent as CheckMark } from "../../resources/images/checkMark.svg";
import { ReactComponent as BannerPic } from "../../resources/images/bannerNoDiaryViewers.svg";
import UserSharedWith from "../../components/UserSharedWith";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDiaryViewers,
  fetchInviteLink,
  updateShareAccess,
} from "../../store/slices/sharingData";
import { MAIN_BUTTON, TELEGRAM } from "../../util/constants";
import { sendMetrics } from "../../util/metrics";
import Translator from "../../resources/localization/translator";
import useUserParamsStore from "../../stores/useUserParamsStore";

const SharingPage: React.FC = () => {
  const { apiToken, language, firstTimeOpen } = useUserParamsStore();
  const { diaryViewers, access, revokeShare, inviteLink } = useSelector(
    (state: SharingDataSlice) => state.sharingData
  );
  const dispatch = useDispatch();
  const setStartPage = useUserParamsStore((state) => state.setStartPage);

  React.useEffect(() => {
    if (firstTimeOpen) setStartPage("sharing");
  }, []);

  React.useEffect(() => {
    if (apiToken) {
      dispatch(
        fetchDiaryViewers({
          apiToken: apiToken,
        }) as any
      );
      dispatch(fetchInviteLink({ apiToken: apiToken }) as any);
    }
  }, [apiToken, access, revokeShare]);

  const renderComponent = (diaries: SharingViewersArray) => {
    return diaries.map((diary, index: number) => (
      <UserSharedWith
        key={index}
        name={diary.name}
        tgUsername={diary.tg_username}
        profilePicFileId={diary.profile_pic_file_id}
        shareToken={diary.token}
        access={diary.access}
      />
    ));
  };

  const getNumberOfUsers = (
    diaries: SharingViewersArray,
    language: string
  ): string => {
    const numberOfUsers = diaries.length;
    if (language === "ru") {
      if (numberOfUsers === 1 || numberOfUsers % 10 === 1) {
        return "1 пользователь";
      } else if (numberOfUsers > 1 && numberOfUsers < 5) {
        return `${numberOfUsers} пользователя`;
      } else {
        return `${numberOfUsers} пользователей`;
      }
    } else if (language === "en") {
      if (numberOfUsers === 1) {
        return "1 user";
      } else {
        return `${numberOfUsers} users`;
      }
    }
    return "";
  };

  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);
  const [linkForMainButton, setLinkForMainButton] = React.useState("");
  const [tokenForMainButton, setTokenForMainButton] = React.useState("");

  React.useEffect(() => {
    if (inviteLink && "link" in inviteLink) {
      setLinkForMainButton(inviteLink.link);
      setTokenForMainButton(inviteLink.token);
      setSelectedCategory(inviteLink.access);
    }
  }, [inviteLink]);

  const handleRadioChange = (event) => {
    sendMetrics("reachGoal", "click-sharing-settings-for-token-radio");
    setSelectedCategory(event.target.value);
    TELEGRAM.HapticFeedback.impactOccurred("light");
  };

  const handleShareButtonClick = () => {
    sendMetrics("reachGoal", "click-share-btn");
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    if (inviteLink && "link" in inviteLink) {
      const link = inviteLink.link;

      navigator.clipboard
        .writeText(link)
        .then(() => {
          dispatch(
            updateShareAccess({
              apiToken: apiToken,
              shareToken: inviteLink.token,
              newAccess: selectedCategory,
            }) as any
          );
        })
        .catch((error) => {
          console.error("Failed to copy link to clipboard:", error);

          if (navigator.userAgent.match(/Android/i)) {
            const textArea = document.createElement("textarea");
            textArea.value = link;

            textArea.style.position = "fixed";
            textArea.style.opacity = "0";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
              const successful = document.execCommand("copy");
              const msg = successful ? "successful" : "unsuccessful";
            } catch (err) {
              console.error("Fallback copy attempt failed:", err);
            }

            document.body.removeChild(textArea);
          }
        })
        .then(() => {
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
          }, 2000);
        });
    }
  };

  React.useEffect(() => {
    if (
      diaryViewers &&
      "viewers" in diaryViewers &&
      diaryViewers.viewers.length === 0
    ) {
      MAIN_BUTTON.setParams({
        text: Translator.translate("COPY_LINK", language),
        is_visible: true,
      });
      TELEGRAM.onEvent("mainButtonClicked", handleShareButtonClick);
      return () => {
        TELEGRAM.offEvent("mainButtonClicked", handleShareButtonClick);
      };
    }
  }, [diaryViewers, inviteLink, selectedCategory]);

  return (
    <div className="sharing-page">
      <h1 className="sharing-page__title">
        {Translator.translate("SHARE_DIARY", language)}
      </h1>
      {diaryViewers &&
        "viewers" in diaryViewers &&
        diaryViewers.viewers.length === 0 && (
          <div className="sharing-page-banner">
            <div>
              <h1 className="sharing-page-banner__title">
                {Translator.translate("BANNER_SHARE_TITLE", language)}
              </h1>
              <p className="sharing-page-banner__description">
                {Translator.translate("BANNER_SHARE_DESCRIPTION", language)}
              </p>
            </div>
            <div className="sharing-page-banner__picture">
              <BannerPic />
            </div>
          </div>
        )}
      <div className="sharing-page-description">
        <div className="sharing-page-description__content">
          <p className="sharing-page-description__title">
            {Translator.translate("WHAT_TO_SHARE", language)}
          </p>
        </div>
      </div>
      <div className="sharing-page-categories">
        <div className="sharing-page-categories__item">
          <input
            type="radio"
            id="radio1"
            name="category"
            value="default"
            onChange={handleRadioChange}
            checked={selectedCategory === "default"}
          />
          {/* <div className="sharing-page-categories-info"> */}
          <label
            htmlFor="radio1"
            className="sharing-page-categories-info__label"
          >
            {Translator.translate("ONLY_MOOD", language)}
            <p className="sharing-page-categories-info__description">
              {Translator.translate("ONLY_MOOD_DESCRIPTION", language)}
            </p>
          </label>
          <CheckMark className="radio-icon" />
          {/* </div> */}
        </div>

        <div className="sharing-page-categories__item">
          <input
            type="radio"
            id="radio2"
            name="category"
            value="emotions"
            onChange={handleRadioChange}
            checked={selectedCategory === "emotions"}
          />
          {/* <div className="sharing-page-categories-info"> */}
          <label
            htmlFor="radio2"
            className="sharing-page-categories-info__label"
          >
            {Translator.translate("EMOTIONS_AND_REASONS", language)}
            <p className="sharing-page-categories-info__description">
              {Translator.translate(
                "EMOTIONS_AND_REASONS_DESCRIPTION",
                language
              )}
            </p>
          </label>
          <CheckMark className="radio-icon" />
          {/* </div> */}
        </div>

        <div className="sharing-page-categories__item">
          <input
            type="radio"
            id="radio3"
            name="category"
            value="all"
            onChange={handleRadioChange}
            checked={selectedCategory === "all"}
          />
          {/* <div className="sharing-page-categories-info"> */}
          <label
            htmlFor="radio3"
            className="sharing-page-categories-info__label"
          >
            {Translator.translate("ALL", language)}
            <p className="sharing-page-categories-info__description">
              {Translator.translate("ALL_DESCRIPTION", language)}
            </p>
          </label>
          <CheckMark className="radio-icon" />
          {/* </div> */}
        </div>
      </div>
      <div className="share-btn-container">
        {diaryViewers &&
          "viewers" in diaryViewers &&
          diaryViewers.viewers.length > 0 && (
            <>
              <div className={`link-popup ${showPopup ? "" : "hidden"}`}>
                <CheckMark />
                <p>{Translator.translate("LINK_COPIED", language)}</p>
              </div>
              <button
                className="sharing-button"
                onClick={handleShareButtonClick.bind(
                  null,
                  linkForMainButton,
                  tokenForMainButton
                )}
              >
                <p className="sharing-button__text">
                  <ShareIcon /> {Translator.translate("COPY_LINK", language)}
                </p>
              </button>
            </>
          )}
      </div>
      {diaryViewers &&
        "viewers" in diaryViewers &&
        diaryViewers.viewers.length > 0 && (
          <div className="sharing-page-description">
            <div className="sharing-page-description__content">
              <p className="sharing-page-description__title">
                {Translator.translate("CAN_VIEW", language)}
              </p>
              <p className="sharing-page-description__subtitle">
                {diaryViewers && "viewers" in diaryViewers
                  ? getNumberOfUsers(diaryViewers.viewers, language)
                  : null}
              </p>
            </div>
          </div>
        )}
      {diaryViewers && "viewers" in diaryViewers
        ? renderComponent(diaryViewers.viewers)
        : null}
      {diaryViewers &&
        "viewers" in diaryViewers &&
        diaryViewers.viewers.length === 0 && (
          <div className="link-popup-tg-btn-container">
            <div className={`link-popup-tg-btn ${showPopup ? "" : "hidden"}`}>
              <CheckMark />
              <p>{Translator.translate("LINK_COPIED", language)}</p>
            </div>
          </div>
        )}
    </div>
  );
};

export default SharingPage;
