import { Doughnut, Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useDispatch } from "react-redux";
import { getDateRange } from "../../util/helpers";
import { fetchChartData } from "../../store/slices/moodChartData";
import { createGradient, setChartGridColor } from "../../util/charts";
import { formatDates } from "../../util/dates";
import {
  API,
  MOOD_CHART_COLORS,
  MOOD_CHART_COLORS_REVERSE,
  TELEGRAM,
} from "../../util/constants";
import { imagePlugin } from "../../util/chartPlugins";
import { ReactComponent as BannerNoFriends } from "../../resources/images/bannerNoFriends.svg";
import awful from "../../resources/images/awful.svg";
import bad from "../../resources/images/bad.svg";
import average from "../../resources/images/average.svg";
import good from "../../resources/images/good.svg";
import perfect from "../../resources/images/perfect.svg";
import { sendMetrics } from "../../util/metrics";
import Translator from "../../resources/localization/translator";
import useUserParamsStore from "../../stores/useUserParamsStore";

const MoodChart = ({ dateRange }) => {
  const {
    chartDate,
    chartData,
    chartDataLoadingStatus,
    withFriends,
    hasFriends,
    checkupsTotal,
  } = useSelector((state) => state.moodChartData);
  const { colorScheme, themeParams, apiToken, language, viewedByAdmin } =
    useUserParamsStore();
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const chartGradientColors = MOOD_CHART_COLORS;
  const images = [
    "https://imagedelivery.net/zIr3GxLP3Gpv7j7rSbRbUA/edeaa771-d230-413d-fafa-35770b5ba900/public",
    "https://imagedelivery.net/zIr3GxLP3Gpv7j7rSbRbUA/7af1140d-b16c-4226-e0f5-3660a8619800/public",
    "https://imagedelivery.net/zIr3GxLP3Gpv7j7rSbRbUA/5be21fa4-387d-4c68-2b41-142405ea2300/public",
    "https://imagedelivery.net/zIr3GxLP3Gpv7j7rSbRbUA/17e0301d-1081-4148-2762-c6079f9d6d00/public",
    "https://imagedelivery.net/zIr3GxLP3Gpv7j7rSbRbUA/9d31a5ac-f917-4290-5679-4f87b3bd0c00/public",
  ];
  const skipped = (ctx, value) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const countNonNullValues = (data) => {
    let count = 0;

    for (let i = 0; i < data?.length; i++) {
      if (data[i] !== null) {
        count++;
      }
    }

    return count;
  };
  // const [checkupsCount, setCheckupsCount] = useState([{ data: "" }]);

  // const [checkupsCount, setCheckupsCount] = useState(chartData[0]?.total || 0);

  // useEffect(() => {
  //   if (chartData.length !== 0) {
  //     setCheckupsCount(chartData[0]?.total);
  //   }
  // }, [chartData]);

  useEffect(() => {
    dispatch(
      fetchChartData({
        chartDate: chartDate,
        dateRange: dateRange,
        withFriends: withFriends,
        apiToken: apiToken,
        lang: language,
      })
    );
  }, [chartDate, dateRange, withFriends, apiToken]);

  const mockData = {
    week: {
      labels: formatDates(getDateRange(chartDate, dateRange), dateRange),
      datasets: [
        {
          data: [5, 4, 5, 3, 4, 4, 5],
          fill: false,
          borderColor: "#B7D58B",
          tension: 0.1,
          yAxisID: "y",
          image: images,
        },
      ],
    },
    lastAndCurrentWeek: {
      labels: formatDates(getDateRange(chartDate, dateRange), dateRange),
      datasets: [
        {
          data: [5, 4, 5, 3, 4, 4, 5, 3, 2, 5, 3, 4],
          fill: false,
          borderColor: "#B7D58B",
          tension: 0.1,
          yAxisID: "y",
          image: images,
        },
      ],
    },
    month: {
      labels: ["Ужасно", "Плохо", "Нормально", "Хорошо", "Прекрасно"],
      datasets: [
        {
          data: [3, 4, 5, 6, 3],
          backgroundColor: MOOD_CHART_COLORS_REVERSE,
          datalabels: {
            anchor: "center",
            align: "center",
            color: "#fff",
          },
          borderWidth: 0,
        },
      ],
    },
  };
  const [userData, setUserData] = useState(mockData[dateRange]);
  const commonOptions = {
    responsive: true,
    layout: {
      padding: {
        left: 35,
        top: 15,
      },
    },
    plugins: {
      tooltip: { enabled: false },
      legend: {
        display: withFriends ? true : false,
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxHeight: 9,
          pointStyleWidth: 12,
          font: {
            family: "manrope",
            size: 10,
            weight: "bold",
          },
        },
      },
      datalabels: {
        font: {
          family: "manrope",
          size: 12,
          weight: "bold",
        },
        formatter: function (value) {
          return "";
        },
      },
    },
    animation: {
      duration: 1000,
      delay: (context) => context.dataIndex * 100,
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: setChartGridColor(
            colorScheme,
            "rgba(29, 25, 56, 0.1)",
            "rgba(255, 255, 255, 0.15)"
          ),
        },
        ticks: {
          color: themeParams.text_color,
          beginAtZero: true,
          padding: 6,
          font: {
            size: 10,
          },
          maxRotation: 60,
          minRotation: 0,
        },
        border: {
          color: setChartGridColor(
            colorScheme,
            "rgba(29, 25, 56, 0.1)",
            "rgba(255, 255, 255, 0.15)"
          ),
        },
      },
      y: {
        suggestedMax: 5,
        suggestedMin: 1,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: setChartGridColor(
            colorScheme,
            "rgba(29, 25, 56, 0.1)",
            "rgba(255, 255, 255, 0.15)"
          ),
        },
        ticks: {
          font: {
            size: 16,
          },
          beginAtZero: false,
          stepSize: 1,
          count: 5,
          callback: function (value) {
            switch (value) {
              case 1:
                return "";
              case 2:
                return "";
              case 3:
                return "";
              case 4:
                return "";
              case 5:
                return "";
              default:
                return "?";
            }
          },
        },
        border: {
          color: setChartGridColor(
            colorScheme,
            "rgba(29, 25, 56, 0.1)",
            "rgba(255, 255, 255, 0.15)"
          ),
        },
      },
    },
  };

  const chartOptions = {
    week: {
      ...commonOptions,
      height: 214,
      scales: {
        ...commonOptions.scales,
        x: {
          ...commonOptions.scales.x,
          maxTicksLimit: 7,
        },
      },
    },
    lastAndCurrentWeek: {
      ...commonOptions,
      height: 143,
      scales: {
        ...commonOptions.scales,
        x: {
          ...commonOptions.scales.x,
          ticks: {
            ...commonOptions.scales.x.ticks,
            stepSize: 2,
            // callback: function (value, index) {
            //   return index % 2 === 0 ? "" : value;
            // },
          },
        },
      },
    },
    month: {
      ...commonOptions,
      height: 143,
      scales: {
        ...commonOptions.scales,
        x: {
          ...commonOptions.scales.x,
          ticks: {
            ...commonOptions.scales.x.ticks,
            stepSize: 2,
            // callback: function (value, index) {
            //   return index % 2 === 0 ? "" : value;
            // },
          },
        },
      },
      // plugins: {
      //   // legend: legendOptions,
      //   legend: { display: false },
      //   datalabels: {
      //     font: {
      //       family: "manrope",
      //       size: 12,
      //       weight: "bold",
      //     },
      //     formatter: function (value) {
      //       if (value === 0) {
      //         return "";
      //       }
      //       return value;
      //     },
      //   },
      // },
      // animation: {
      //   ...commonOptions.animation,
      //   duration: 1000,
      // },
      // scales: {
      //   y: {
      //     display: false,
      //   },
      // },
    },
  };

  const options = chartOptions[dateRange];

  useEffect(() => {
    // if (chartData.length !== 0) {
    if (dateRange === "month1") {
      const labels = ["Ужасно", "Плохо", "Нормально", "Хорошо", "Прекрасно"];
      const monthChartData = {
        labels: labels,
        datasets: [
          {
            data: Array(5).fill(0),
            backgroundColor: MOOD_CHART_COLORS_REVERSE,
            datalabels: {
              anchor: "center",
              align: "center",
              color: "#fff",
            },
            borderWidth: 0,
          },
        ],
      };
      chartData[0]?.data.forEach((value) => {
        monthChartData.datasets[0].data[value - 1]++;
      });
      if (checkupsTotal !== 0) setUserData(monthChartData);
      else setUserData(mockData[dateRange]);
    } else {
      const chart = chartRef.current;

      if (!chart) {
        return;
      }
      const gradientColor = createGradient(
        chart.ctx,
        chart.chartArea,
        chartGradientColors
      );
      const colors = [
        "#7868E6",
        "#68B9E6",
        "#68E675",
        "#E66868",
        "#FF9FF3",
        "#03A9F4",
        "#607D8B",
        "#4CAF50",
        "#F44336",
        "#8D48FE",
      ];
      const datasets = [];
      if (withFriends) {
        if (Array.isArray(chartData) && chartData.length > 0) {
          chartData.map((user, index) => {
            const dataset = {
              label: index === 0 ? "Я" : user.label,
              data: user.data,
              fill: false,
              borderColor: withFriends ? colors[index] : gradientColor,
              tension: 0.1,
              pointRadius: 4 + index * 1.5,
              pointBackgroundColor: withFriends ? colors[index] : gradientColor,
              spanGaps: true,
              segment: {
                borderColor: (ctx) => skipped(ctx, "rgba(64, 64, 64, 0.5)"),
                borderDash: (ctx) => skipped(ctx, [5, 5]) || [],
              },
              image: images,
            };

            datasets.push(dataset);
          });
        }
      } else {
        if (Array.isArray(chartData) && chartData.length > 0) {
          const dataset = {
            data: chartData[0]?.data,
            fill: false,
            borderColor: gradientColor,
            tension: 0.1,
            pointRadius: 4,
            pointBackgroundColor: gradientColor,
            spanGaps: true,
            segment: {
              borderColor: (ctx) => skipped(ctx, "rgba(64, 64, 64, 0.5)"),
              borderDash: (ctx) => skipped(ctx, [5, 5]) || [],
            },
            image: images,
          };

          datasets.push(dataset);
        }
      }

      if (checkupsTotal !== 0) {
        setUserData({
          labels: formatDates(getDateRange(chartDate, dateRange), dateRange),
          datasets: datasets,
        });
      } else {
        setUserData(mockData[dateRange]);
      }
    }
    // }
  }, [chartDate, dateRange, chartData, withFriends, checkupsTotal]);

  const renderChart = (data, options, dateRange) => {
    if (chartDataLoadingStatus === "loading") {
      return (
        <section
          className={`mood-chart-skeleton_${dateRange}${
            withFriends ? "_withFriends" : ""
          }`}
        >
          <div
            className={`mood-chart-skeleton_${dateRange}${
              withFriends ? "_withFriends" : ""
            }-container`}
          >
            <div
              className={`mood-chart-skeleton_${dateRange}${
                withFriends ? "_withFriends" : ""
              }`}
            >
              <div
                className={`mood-chart-skeleton_${dateRange}${
                  withFriends ? "_withFriends" : ""
                } skeleton`}
              ></div>
            </div>
          </div>
        </section>
      );
    }
    if (chartDataLoadingStatus === "idle") {
      if (withFriends && !hasFriends) {
        return (
          <div className="svg">
            <BannerNoFriends onClick={clickHandler} />
          </div>
        );
      }
      if (dateRange === "week")
        return (
          <Line
            key={`line-chart-${dateRange}`}
            ref={chartRef}
            data={data}
            plugins={[imagePlugin]}
            options={options}
            height={withFriends ? 276 : 244}
          />
        );
      if (dateRange === "lastAndCurrentWeek")
        return (
          <Line
            key={`line-chart-${dateRange}`}
            ref={chartRef}
            data={data}
            plugins={[imagePlugin]}
            options={options}
            height={withFriends ? 198 : 174}
          />
        );
      if (dateRange === "month") {
        return (
          // <Doughnut
          //   key={`doughnut-chart-${dateRange}`}
          //   data={data}
          //   plugins={[ChartDataLabels]}
          //   options={options}
          // />
          <Line
            key={`line-chart-${dateRange}`}
            ref={chartRef}
            data={data}
            plugins={[imagePlugin]}
            options={options}
            height={withFriends ? 198 : 174}
          />
        );
      }
    }
  };

  const openFriendsMenu = async (data) => {
    try {
      const response =
        await API.openFriendsMenu.sentFriendsMenuOpenFriendsMenuPost({
          api_token: apiToken,
        });

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return error;
    }
  };

  const sendData = () => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    sendMetrics("reachGoal", "click-add-friend-btn");
    if (!viewedByAdmin) {
      openFriendsMenu();
    }
    setTimeout(() => {
      TELEGRAM.close();
    }, 0);
  };
  const clickHandler = () => {
    if (clickHandler.cantClick) return;
    clickHandler.cantClick = true;

    sendData();

    setTimeout(() => {
      clickHandler.cantClick = false;
    }, 500);
  };

  return (
    <div
      className={`chart-wrapper${dateRange === "month1" ? "_no-bg" : ""}${
        checkupsTotal < 2 && chartDataLoadingStatus === "idle" ? "_empty" : ""
      }`}
    >
      {chartDataLoadingStatus === "idle" ? (
        <p className={`chart-text${checkupsTotal < 2 ? "_empty" : ""}`}>
          Чтобы увидеть статистику, необходимо пройти хотя бы 2 чекапа
        </p>
      ) : null}
      <div
        className={
          chartDataLoadingStatus === "idle"
            ? `mood-chart-container_${dateRange}${
                withFriends && dateRange !== "month1" ? "_withFriends" : ""
              }${checkupsTotal < 2 ? "_blur" : ""}${
                withFriends && chartData.length <= 1 && dateRange !== "month1"
                  ? "_hidden"
                  : ""
              }`
            : null
        }
      >
        <div className={`chart${dateRange === "month1" ? "_month" : ""}`}>
          {renderChart(userData, options, dateRange)}
        </div>
        {dateRange === "month1" && chartDataLoadingStatus === "idle" && (
          <div className="legend">
            <div>
              <div className="pie-label">
                <img src={perfect} alt="perfect" />
                Отлично
              </div>
              <div className="pie-label">
                <img src={good} alt="good" />
                Хорошо
              </div>
              <div className="pie-label">
                <img src={average} alt="average" />
                Нормально
              </div>
              <div className="pie-label">
                <img src={bad} alt="bad" />
                Не очень
              </div>
              <div className="pie-label">
                <img src={awful} alt="awful" />
                Плохо
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MoodChart;
