import { create } from "zustand";

type UserParams = {
  apiToken: string | null;
  language: string;
  gender: string;
  viewedByAdmin: boolean | null;
  colorScheme: string;
  themeParams: Record<string, any>;
  shareToken: string | null;
  startPage: string | null;
  firstTimeOpen: boolean;
  initialPage: string | null;
  setApiToken: (token: string | null) => void;
  setLanguage: (lang: string) => void;
  setGender: (gender: string) => void;
  setViewedByAdmin: (viewed: boolean | null) => void;
  setColorScheme: (scheme: string) => void;
  setThemeParams: (params: Record<string, any>) => void;
  setShareToken: (token: string | null) => void;
  setStartPage: (page: string | null) => void;
  setFirstTimeOpen: (status: boolean) => void;
  setInitialPage: (page: string | null) => void;
};

const useUserParamsStore = create<UserParams>((set) => ({
  apiToken: null,
  language: "ru",
  gender: "male",
  viewedByAdmin: null,
  colorScheme: "light",
  themeParams: {},
  shareToken: null,
  startPage: null,
  firstTimeOpen: true,
  initialPage: null,

  setApiToken: (token) => set({ apiToken: token }),
  setLanguage: (lang) => set({ language: lang }),
  setGender: (gender) => set({ gender }),
  setViewedByAdmin: (viewed) => set({ viewedByAdmin: viewed }),
  setColorScheme: (scheme) => set({ colorScheme: scheme }),
  setThemeParams: (params) => set({ themeParams: params }),
  setShareToken: (token) => set({ shareToken: token }),
  setStartPage: (page) => set({ startPage: page, firstTimeOpen: false }),
  setFirstTimeOpen: (status) => set({ firstTimeOpen: status }),
  setInitialPage: (page) => set({ initialPage: page }),
}));

export default useUserParamsStore;
