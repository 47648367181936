import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { API } from "../../../util/constants";

const parseChartData = (data) => {
  const datasets = [];
  const categories = ["awful", "bad", "average", "good", "perfect"];

  if (Object.keys(data).length === 0) return;

  data?.data.forEach((user) => {
    const userDataset = {
      label: user.label,
      data: [],
      total: user.total,
    };
    user.data.forEach((record) => {
      const value = categories.indexOf(record.value) + 1;
      userDataset.data.push(value > 0 ? value : null);
    });
    datasets.push(userDataset);
  });

  return datasets;
};

const initialState = {
  chartDate: new Date(),
  chartDataLoadingStatus: "loading",
  chartData: [],
  withFriends: false,
  hasFriends: false,
  checkupsTotal: 0,
};

export const fetchChartData = createAsyncThunk(
  "moodChartData/fetchChartData",
  async (
    { chartDate, dateRange, withFriends, apiToken, lang },
    { rejectWithValue }
  ) => {
    let start, end;
    switch (dateRange) {
      case "week":
        start = startOfWeek(chartDate, { weekStartsOn: 1 });
        end = endOfWeek(chartDate, { weekStartsOn: 1 });
        break;
      case "lastAndCurrentWeek":
        start = startOfWeek(subWeeks(chartDate, 1), { weekStartsOn: 1 });
        end = endOfWeek(chartDate, { weekStartsOn: 1 });
        break;
      case "month":
        start = startOfMonth(chartDate);
        end = endOfMonth(chartDate);
        break;
      default:
        start = chartDate;
        end = chartDate;
        break;
    }
    const dateFrom = format(start, "yyyy-MM-dd");
    const dateTo = format(end, "yyyy-MM-dd");

    try {
      const response = await API.moodData.getMoodDataMoodDataGet({
        dateFrom: dateFrom,
        dateTo: dateTo,
        withFriends: withFriends,
        api_token: apiToken,
        lang: lang,
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);

const ChartSlice = createSlice({
  name: "moodChartData",
  initialState,
  reducers: {
    setChartDate: (state, { payload }) => {
      state.chartDate = payload;
    },
    setWithFriends: (state, { payload }) => {
      state.withFriends = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChartData.pending, (state) => {
        state.chartDataLoadingStatus = "loading";
      })
      .addCase(fetchChartData.fulfilled, (state, action) => {
        state.chartDataLoadingStatus = "idle";
        state.hasFriends = action.payload.user_has_friends;
        state.checkupsTotal = action.payload.data[0].total;
        state.chartData = parseChartData(action.payload);
      })
      .addCase(fetchChartData.rejected, (state) => {
        state.chartDataLoadingStatus = "error";
      })
      .addDefaultCase(() => {});
  },
});

const { actions, reducer } = ChartSlice;

export default reducer;
export const { setChartDate, setWithFriends } = actions;
