import { FC, PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TELEGRAM } from "../../util/constants";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/slices/navigation";
import { sendMetrics } from "../../util/metrics";
import useUserParamsStore from "../../stores/useUserParamsStore";

const PageWrapper: FC<PropsWithChildren> = ({ children }) => {
  const startPage = useUserParamsStore((state) => state.startPage);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const goBack = () => {
    navigate(-1);
    sendMetrics("reachGoal", "click-go-back-btn");
  };

  useEffect(() => {
    dispatch(setCurrentPage(location.pathname.replace(/^\//, "")));

    const isInitialPage =
      location.pathname === "/" || location.pathname === `/${startPage}`;

    if (!isInitialPage) {
      TELEGRAM.BackButton.show();
      TELEGRAM.BackButton.onClick(goBack);
    } else {
      TELEGRAM.BackButton.hide();
    }

    return () => {
      TELEGRAM.BackButton.offClick(goBack);
    };
  }, [location.pathname, startPage]);

  return <>{children}</>;
};

export default PageWrapper;
