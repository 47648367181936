import { format, sub, add, isAfter, isSameDay, isSameWeek } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MoodChart from "../../components/MoodChart";
import "./ChartPage.scss";
import "../../resources/styles/skeleton.scss";
import PageNavigation from "../../UI/PageNavigation";
import { getDatesInterval } from "../../util/helpers";
import { setChartDate, setWithFriends } from "../../store/slices/moodChartData";
import MoodFactorsChart from "../../components/MoodFactorsChart";
import EmotionsStatsChart from "../../components/EmotionsStatsChart";
import { setCurrentPage } from "../../store/slices/navigation";
import CustomQuestionsChart from "../../components/CustomQuestionsChart";
import { TELEGRAM } from "../../util/constants";
import Translator from "../../resources/localization/translator";
import { sendMetrics } from "../../util/metrics";
import useUserParamsStore from "../../stores/useUserParamsStore";

const ChartPage = () => {
  const setStartPage = useUserParamsStore((state) => state.setStartPage);
  const { chartDate, chartData, withFriends, checkupsTotal } = useSelector(
    (state) => state.moodChartData
  );

  const { colorScheme, language, firstTimeOpen } = useUserParamsStore();
  const [dateRange, setDateRange] = useState("lastAndCurrentWeek");
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstTimeOpen) setStartPage("statistics");
  }, []);

  const dateRangeHandler = (dateRange) => {
    TELEGRAM.HapticFeedback.impactOccurred("light");
    setDateRange(dateRange);
    switch (dateRange) {
      case "week":
        sendMetrics("reachGoal", "click-one-week-btn");
        break;
      case "lastAndCurrentWeek":
        sendMetrics("reachGoal", "click-two-weeks-btn");
        break;
      case "month":
        sendMetrics("reachGoal", "click-month-btn");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    TELEGRAM.setHeaderColor(TELEGRAM.themeParams.bg_color);
    dispatch(setCurrentPage("statistics"));
  }, []);

  const getCheckupsCount = (obj, lang = "ru") => {
    if (lang === "ru") {
      if (!obj || !obj[0]?.data) {
        return "0 чекапов";
      }

      const count = obj[0]?.data.reduce((acc, val) => {
        if (val !== null) {
          return acc + 1;
        }
        return acc;
      }, 0);

      let suffix = "ов";
      if (count === 1 || count === 21 || count === 31) {
        suffix = "";
      } else if ((count >= 2 && count <= 4) || (count >= 22 && count <= 24)) {
        suffix = "а";
      }

      return `${count} чекап${suffix}`;
    }
    if (lang === "en") {
      if (!obj || !obj[0]?.data) {
        return "0 checkups";
      }

      const count = obj[0]?.data.reduce((acc, val) => {
        if (val !== null) {
          return acc + 1;
        }
        return acc;
      }, 0);

      let suffix = "s";
      if (count === 1 || count === 21 || count === 31) {
        suffix = "";
      } else if ((count >= 2 && count <= 4) || (count >= 22 && count <= 24)) {
        suffix = "s";
      }

      return `${count} checkup${suffix}`;
    }
  };

  const getChartDate = (date, mode, range, shift) => {
    TELEGRAM.HapticFeedback.impactOccurred("light");
    let result = null;
    if (mode === "sub") {
      sendMetrics("reachGoal", "click-arrow-prev-btn");
      switch (range) {
        case "week":
          result = sub(date, {
            weeks: shift,
          });
          break;
        case "lastAndCurrentWeek":
          result = sub(date, {
            weeks: shift + 1,
          });
          break;
        case "month":
          result = sub(date, {
            months: shift,
          });
          break;
        default:
          break;
      }
    }
    if (mode === "add") {
      if (isAfter(date, new Date()) || isSameDay(date, new Date())) {
        return new Date();
      } else {
        sendMetrics("reachGoal", "click-arrow-next-btn");
        switch (range) {
          case "week":
            result = add(date, {
              weeks: shift,
            });
            break;
          case "lastAndCurrentWeek":
            result = add(date, {
              weeks: shift + 1,
            });
            break;
          case "month":
            result = add(date, {
              months: shift,
            });
            break;
          default:
            break;
        }
      }
    }

    dispatch(setChartDate(result));
    return result;
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) dispatch(setWithFriends(true));
    else dispatch(setWithFriends(false));
  };
  const todayButtonHandler = () => {
    sendMetrics("reachGoal", "click-today-btn");
    dispatch(setChartDate(new Date()));
  };
  return (
    <main>
      <div className="chart-page-content">
        <div
          className={`chart-page-navigation${
            colorScheme === "light" ? "" : "_dark"
          }`}
        >
          <div className="chart-page-panel">
            <div className="chart-page-panel-content">
              <button
                className={`chart-page-panel-content__item ${
                  dateRange === "week" ? "btn-active" : ""
                }`}
                onClick={() => dateRangeHandler("week")}
              >
                <p>{Translator.translate("ONE_WEEK", language)}</p>
              </button>
              <button
                className={`chart-page-panel-content__item ${
                  dateRange === "lastAndCurrentWeek" ? "btn-active" : ""
                }`}
                onClick={() => dateRangeHandler("lastAndCurrentWeek")}
              >
                <p>{Translator.translate("TWO_WEEKS", language)}</p>
              </button>
              <button
                className={`chart-page-panel-content__item ${
                  dateRange === "month" ? "btn-active" : ""
                }`}
                onClick={() => dateRangeHandler("month")}
              >
                <p>{Translator.translate("MONTH", language)}</p>
              </button>
            </div>
            <div className="chart-page-panel-footer">
              <button
                onClick={() => getChartDate(chartDate, "sub", dateRange, 1)}
                className="nav-btn"
              >
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 21 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.292893 8.70711C-0.0976314 8.31658 -0.0976315 7.68342 0.292892 7.2929L6.65685 0.928933C7.04738 0.538409 7.68054 0.538409 8.07107 0.928933C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292893 8.70711ZM21 9L1 9L1 7L21 7L21 9Z"
                    fill="#7868E6"
                  />
                </svg>
              </button>
              <div className="time-interval-panel">
                <div className="date-panel">
                  <p>
                    {format(
                      getDatesInterval(chartDate, dateRange, "start"),
                      "dd/MM/yy"
                    )}{" "}
                    —{" "}
                    {format(
                      getDatesInterval(chartDate, dateRange, "end"),
                      "dd/MM/yy"
                    )}
                  </p>
                  <p className="counter">
                    {getCheckupsCount(chartData, language)}
                  </p>
                </div>
                {!isSameDay(chartDate, new Date()) ? (
                  <button className="today-btn" onClick={todayButtonHandler}>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.2857 7.0004C12.2861 8.20315 11.882 9.36958 11.1409 10.3046C10.3998 11.2396 9.36663 11.8865 8.21429 12.1371C7.06195 12.3876 5.86027 12.2267 4.81023 11.6812C3.7602 11.1356 2.92549 10.2386 2.44571 9.14009L0.856 9.79926C1.4862 11.2733 2.59432 12.4809 3.99408 13.2191C5.39383 13.9572 6.99986 14.1809 8.54215 13.8525C10.0844 13.5241 11.4689 12.6636 12.4629 11.4157C13.4568 10.1679 13.9996 8.60872 14 7.0004C14.0001 5.53941 13.5523 4.115 12.7196 2.92682C11.8868 1.73864 10.7107 0.84623 9.35614 0.374664C8.00158 -0.0969024 6.53643 -0.12399 5.16603 0.297197C3.79562 0.718384 2.58866 1.56674 1.71429 2.72336V0.583667H0V5.25038L0.857143 6.12539H4.85714V4.37537H2.688C3.25384 3.37398 4.12762 2.59124 5.17377 2.14861C6.21992 1.70599 7.37993 1.62823 8.4738 1.92741C9.56766 2.22659 10.5342 2.88597 11.2235 3.80323C11.9127 4.7205 12.2861 5.84435 12.2857 7.0004Z"
                        fill="#7868E6"
                      />
                    </svg>
                  </button>
                ) : (
                  <div
                    style={{
                      minHeight: "14px",
                      minWidth: "14px",
                      marginLeft: "8px",
                    }}
                  ></div>
                )}
              </div>
              <button
                onClick={() => getChartDate(chartDate, "add", dateRange, 1)}
                className="nav-btn"
              >
                {isSameWeek(chartDate, new Date()) ||
                isAfter(chartDate, new Date()) ? (
                  <div className="opacity">
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 21 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.292893 8.70711C-0.0976314 8.31658 -0.0976315 7.68342 0.292892 7.2929L6.65685 0.928933C7.04738 0.538409 7.68054 0.538409 8.07107 0.928933C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292893 8.70711ZM21 9L1 9L1 7L21 7L21 9Z"
                        fill="#7868E6"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="rotate">
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 21 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.292893 8.70711C-0.0976314 8.31658 -0.0976315 7.68342 0.292892 7.2929L6.65685 0.928933C7.04738 0.538409 7.68054 0.538409 8.07107 0.928933C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292893 8.70711ZM21 9L1 9L1 7L21 7L21 9Z"
                        fill="#7868E6"
                      />
                    </svg>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        {dateRange !== "month1" && checkupsTotal > 1 ? (
          <div className="friends-toggle">
            <label className="switch">
              <input
                type="checkbox"
                checked={withFriends}
                onChange={handleCheckboxChange}
              />
              <span className="slider round"></span>
            </label>
            <p className="friends-toggle-description">
              {Translator.translate("SHOW_FRIENDS", language)}
            </p>
          </div>
        ) : null}

        <MoodChart dateRange={dateRange} />

        <MoodFactorsChart dateRange={dateRange} />

        <EmotionsStatsChart dateRange={dateRange} />

        <CustomQuestionsChart dateRange={dateRange} />
      </div>
      <PageNavigation />
    </main>
  );
};
export default ChartPage;
