import React, { useEffect, useRef, useState } from "react";
import sub from "date-fns/sub";
import SingleMonth from "../SingleMonth";
import { useSelector } from "react-redux";
import InfiniteScroll from "../InfiniteScroll";
import isBefore from "date-fns/isBefore";
import { throttle } from "../../util/helpers";
import { MAIN_BUTTON, TELEGRAM } from "../../util/constants";
import kukometr_promo from "../../resources/images/kukometr_promo.jpg";
import styles from "./CalendarLayout.module.scss";
import useCalendarStore from "../../stores/useCalendarStore";
import useUserParamsStore from "../../stores/useUserParamsStore";

const CalendarLayout = () => {
  const fetchDatesWithCheckup = useCalendarStore(
    (state) => state.fetchDatesWithCheckup
  );
  const setTarget = useCalendarStore((state) => state.setTarget);
  const setScrollPosition = useCalendarStore(
    (state) => state.setScrollPosition
  );
  const changeMonthCounterDate = useCalendarStore(
    (state) => state.changeMonthCounterDate
  );
  const datesArray = useCalendarStore((state) => state.datesArray);
  const monthCounterDate = useCalendarStore((state) => state.monthCounterDate);
  const firstCheckupDate = useCalendarStore((state) => state.firstCheckupDate);
  const addMonth = useCalendarStore((state) => state.addMonth);
  const setQueryToggle = useCalendarStore((state) => state.setQueryToggle);
  const queryToggle = useCalendarStore((state) => state.queryToggle);
  const toggleBackToTopVisible = useCalendarStore(
    (state) => state.toggleBackToTopVisible
  );
  const backToTopVisible = useCalendarStore((state) => state.backToTopVisible);
  const resetDataToggle = useCalendarStore((state) => state.resetDataToggle);
  const scrollPosition = useCalendarStore((state) => state.scrollPosition);
  const shareToken = useUserParamsStore((state) => state.shareToken);
  const { isUserLoaded } = useSelector((state) => state.sharingData);
  const refComponentHeight = useRef(null);
  //const params = useParams();
  const location = window.location.search;
  const params = new URLSearchParams(location);
  const apiToken = params.get("token");
  const [scrollToggle, setScrollToggle] = useState(true);
  MAIN_BUTTON.hide();

  //Инициализация в сторе целевого элемента для рассчета координат скролла
  useEffect(() => {
    if (refComponentHeight.current) {
      setTarget(refComponentHeight.current);
    }
  }, [refComponentHeight]);

  //Установка скролла на координату из стора
  useEffect(() => {
    if (scrollPosition && scrollToggle) {
      setTimeout(() => {
        document.documentElement.scrollTop = scrollPosition;
        setScrollPosition(0);
        setScrollToggle(false);
      }, 0);
    }
  }, [scrollPosition, document.documentElement.scrollHeight]);

  useEffect(() => {
    //counterHandler(monthCounterDate);
    //dispatch(toggleBackToTopVisible(false));
    // TELEGRAM.setBackgroundColor("bg_color");
    // dispatch(setColorScheme(TELEGRAM.colorScheme));
    // dispatch(setThemeParams(TELEGRAM.themeParams));
    // dispatch(setLanguage(lang));
    // dispatch(setApiToken(apiToken));
    // dispatch(setViewedByAdmin(viewedByAdmin));
  }, []);

  //Запрос данных с сервера
  useEffect(() => {
    // if (Object.keys(datesWithCheckup) === 0) {
    //   dispatch(setQueryToggle(true));
    // }
    if (
      !isBefore(monthCounterDate, firstCheckupDate) &&
      queryToggle &&
      isUserLoaded
    ) {
      //&& queryToggle
      fetchDatesWithCheckup({
        dataFromComponent: datesArray[datesArray.length - 1],
        isFirstLoad: true,
        apiKey: apiToken,
        shareToken: shareToken,
        resetDataToggle: resetDataToggle,
      });
    }

    setQueryToggle(true);
  }, [monthCounterDate, shareToken, isUserLoaded]);

  //Получение предыдущих месяцев и изменение даты-счетчика в сторе
  const getPrevMonthCounter = (date, shift) => {
    const result = sub(date, {
      months: shift,
    });

    changeMonthCounterDate(result);
    return result;
  };

  //Смещение счетчика на 12 месяцев назад, добавление новых месяцев в массив для рендера
  const counterHandler = (date) => () => {
    const result = getPrevMonthCounter(date, 12);
    addMonth(result);
  };

  //Рендер компонентов-месяцев на основе массива
  const renderMonths = (arr = []) => {
    return arr.map((element, index) => {
      return (
        <SingleMonth key={index} propsDate={element} apiToken={apiToken} />
      );
    });
  };

  //Отслеживание позиции скролла целевого элемента и изменение видимости кнопки возврата вниз страницы
  const handleScroll = ({ target }) => {
    //const value = Math.abs(target.scrollTop);
    const value = document.documentElement.scrollTop;
    if (value > 300) {
      !backToTopVisible && toggleBackToTopVisible(true);
    }

    if (value < 300) {
      backToTopVisible && toggleBackToTopVisible(false);
    }
  };

  return (
    <div className={styles.layout}>
      <div style={{ height: "59px" }}></div>

      <img
        src={kukometr_promo}
        alt="кукометр"
        className={styles.banner}
        onClick={() =>
          TELEGRAM.openLink("https://yarko.help/itogi2024/#kukometr")
        }
      />
      <InfiniteScroll
        onScroll={throttle(handleScroll, 500)}
        ref={refComponentHeight}
        //scrollableTarget="scrollableDiv"
        dataLength={datesArray.length}
        next={counterHandler(monthCounterDate)}
        style={{
          //background: "white",
          boxSizing: "border-box",
          display: "flex",
          flex: "auto",
          flexDirection: "column",
          alignItems: "center",
          overflow: "visible",
          overscrollBehavior: "none",
          //gap: "37px",
          WebkitOverflowScrolling: "touch",
          zIndex: "10",
          position: "relative",
        }}
        hasMore={true}
        //loader={<CalendarLayoutSkeleton />}
        initialScrollY={scrollPosition}
        scrollThreshold={"1000px"}
      >
        {renderMonths(datesArray)}
      </InfiniteScroll>
    </div>
  );
};

export default CalendarLayout;
