import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import CalendarPage from "../../pages/CalendarPage";
import CheckupSkeleton from "../CheckupSkeleton";
import { API, TELEGRAM } from "../../util/constants";
import ChartPage from "../../pages/ChartPage";
import { useDispatch, useSelector } from "react-redux";
import SharingPage from "../../pages/SharingPage/index";
import {
  fetchViewableDiaries,
  setActiveProperties,
  setActiveUserName,
  setActiveUserPicId,
  setActiveUserTgUsername,
  setDiaryOwnerName,
  setDiaryOwnerPicId,
  setDiaryOwnerTgUsername,
  setIsOwnerDiary,
  setIsUserLoaded,
} from "../../store/slices/sharingData";
import YearlyReflectionPage from "../../pages/YearlyReflectionPage";
import PageWrapper from "../../UI/PageWrapper";
import useCalendarStore from "../../stores/useCalendarStore";
import useUserParamsStore from "../../stores/useUserParamsStore";

const CheckupPage = lazy(() => import("../../pages/CheckupPage"));

function App() {
  const [data, setData] = useState();
  const [openAppToggle, setOpenAppToggle] = useState(true);
  const dispatch = useDispatch();
  const location = window.location.search;
  const params = new URLSearchParams(location);
  const apiToken = params.get("token");
  const lang = params.get("language");
  const gender = params.get("gender");
  const shareToken = params.get("to_shared_diary");
  const viewedByAdmin = params.get("viewed_by_admin");

  const { viewableDiaries } = useSelector((state) => state.sharingData);
  const startPage = useUserParamsStore((state) => state.startPage);
  const setResetDataToggle = useCalendarStore(
    (state) => state.setResetDataToggle
  );

  const {
    setColorScheme,
    setThemeParams,
    setLanguage,
    setGender,
    setApiToken,
    setViewedByAdmin,
    setShareToken,
  } = useUserParamsStore();

  async function sendWebAppEvent() {
    await API.openMainMenu.onWebappEvent(
      {
        api_token: apiToken,
      },
      { event: "activated" }
    );
  }

  const safeCall = (method, methodName) => {
    try {
      method();
    } catch (e) {
      console.error(`Ошибка в ${methodName}: `, e);
    }
  };

  useEffect(() => {
    // sendWebAppEvent();
  }, []);

  useEffect(() => {
    safeCall(
      () => (TELEGRAM.isVerticalSwipesEnabled = false),
      "isVerticalSwipesEnabled"
    );
    safeCall(() => TELEGRAM.expand(), "expand");
    safeCall(
      () => TELEGRAM.setBackgroundColor("bg_color"),
      "setBackgroundColor"
    );
    safeCall(() => TELEGRAM.requestFullscreen(), "requestFullscreen");
    safeCall(() => TELEGRAM.lockOrientation(), "lockOrientation");
    safeCall(() => TELEGRAM.ready(), "ready");

    setColorScheme(TELEGRAM.colorScheme);
    setThemeParams(TELEGRAM.themeParams);
    setLanguage(lang);
    setGender(gender);
    setApiToken(apiToken);
    setViewedByAdmin(viewedByAdmin);
    setShareToken(shareToken);
    setData(TELEGRAM.initData);
  }, [data]);

  useEffect(() => {
    if (apiToken) {
      dispatch(
        fetchViewableDiaries({
          apiToken: apiToken,
        })
      );
    }
  }, [apiToken]);

  useEffect(() => {
    if (viewableDiaries && "diaries" in viewableDiaries) {
      dispatch(
        setDiaryOwnerPicId(viewableDiaries.diaries[0]?.profile_pic_file_id)
      );
      dispatch(setDiaryOwnerName(viewableDiaries.diaries[0]?.name));
      dispatch(
        setDiaryOwnerTgUsername(viewableDiaries.diaries[0]?.tg_username)
      );
      if (!shareToken) {
        dispatch(
          setActiveUserPicId(viewableDiaries.diaries[0]?.profile_pic_file_id)
        );
        dispatch(setActiveUserName(viewableDiaries.diaries[0]?.name));
        dispatch(
          setActiveUserTgUsername(viewableDiaries.diaries[0]?.tg_username)
        );
      } else {
        if (viewableDiaries && "diaries" in viewableDiaries) {
          viewableDiaries.diaries.forEach((item) => {
            if (item.token === shareToken) {
              dispatch(setActiveUserPicId(item.profile_pic_file_id));
              dispatch(setActiveUserName(item.name));
              dispatch(setActiveUserTgUsername(item.tg_username));
              dispatch(setActiveProperties(item.access));
              dispatch(setIsOwnerDiary(false));
              setResetDataToggle(true);
            }
          });
        }
      }
      dispatch(setIsUserLoaded(true));
    }
  }, [viewableDiaries]);

  const logWebappOpen = async (data) => {
    try {
      const response =
        await API.logWebappAction.logWebappActionLogWebappActionPost(
          {
            api_token: apiToken,
          },
          {
            start_page: startPage,
            data: TELEGRAM.initDataUnsafe,
            themeParams: TELEGRAM.themeParams,
            device: {
              screenWidth: window.screen.width,
              screenHeight: window.screen.height,
              platform: TELEGRAM.platform,
            },
          }
        );

      return response;
    } catch (err) {
      let error = err;

      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (openAppToggle && !viewedByAdmin && startPage) {
        logWebappOpen();
        setOpenAppToggle(false);
      }
    }, 2000);
  }, [startPage]);

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route
            path={`/calendar`}
            element={
              <PageWrapper>
                <CalendarPage />
              </PageWrapper>
            }
          />
          <Route
            path="/checkup/:checkupDate"
            element={
              <Suspense fallback={<CheckupSkeleton />}>
                <PageWrapper>
                  <CheckupPage />
                </PageWrapper>
              </Suspense>
            }
          />
          <Route
            path={"/statistics"}
            element={
              <PageWrapper>
                <ChartPage />
              </PageWrapper>
            }
          />
          <Route
            path={"/sharing"}
            element={
              <PageWrapper>
                <SharingPage />
              </PageWrapper>
            }
          />
          {/* <Route
            path={"/psychologists"}
            element={
              <PageWrapper>
                <PsychologistsPage />
              </PageWrapper>
            }
          />
          <Route
            path={"/psychologists/:id"}
            element={
              <PageWrapper>
                <PsychologistProfilePage />
              </PageWrapper>
            }
          /> */}
          <Route
            path={"/yearlyReflections"}
            element={
              <PageWrapper>
                <YearlyReflectionPage />
              </PageWrapper>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
