import { configureStore } from "@reduxjs/toolkit";
import pageNavigation from "./slices/navigation";
import moodChartData from "./slices/moodChartData";
import moodFactorsChartData from "./slices/moodFactorsChartData";
import emotionsStatsChartData from "./slices/emotionsStatsChartData";
import customQuestionsData from "./slices/customQuestionsData";
import sharingData from "./slices/sharingData";
import yearlyReflection from "./slices/yearlyReflection";

const stringMiddleware = () => (next) => (action) => {
  if (typeof action === "string") {
    return next({
      type: action,
    });
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    pageNavigation,
    moodChartData,
    moodFactorsChartData,
    emotionsStatsChartData,
    customQuestionsData,
    sharingData,
    yearlyReflection,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(stringMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
