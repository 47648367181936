// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CalendarLayout_layout__XcFHP {
  max-width: 391px;
  min-width: 391px;
  padding: 0 15px;
  margin: 0 auto;
}
.CalendarLayout_layout__XcFHP .CalendarLayout_banner__lTIVI {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/CalendarLayout/CalendarLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AACF;AACE;EACE,WAAA;EACA,mBAAA;EACA,mBAAA;AACJ","sourcesContent":[".layout {\n  max-width: 391px;\n  min-width: 391px;\n  padding: 0 15px;\n  margin: 0 auto;\n\n  .banner {\n    width: 100%;\n    border-radius: 10px;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `CalendarLayout_layout__XcFHP`,
	"banner": `CalendarLayout_banner__lTIVI`
};
export default ___CSS_LOADER_EXPORT___;
