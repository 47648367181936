import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getMonthData,
  areDatesEqual,
  areDatesEqualObj,
  getISODate,
  getLocalMonthNames,
} from "../../util/dates";
import Translator from "../../resources/localization/translator";
import { TELEGRAM, WEEK_DAY_NAMES } from "../../util/constants";
import { sendMetrics } from "../../util/metrics";
import { Link } from "react-router-dom";
import cn from "classnames";
import chatGPTIcon from "../../resources/images/chatGPTIcon.svg";
import manyNotes from "../../resources/images/manyNotesIcon.svg";
import fewNotes from "../../resources/images/fewNotesIcon.svg";
import wisdomIcon from "../../resources/images/wisdomIcon.svg";
import { dayInfoHandler, renderImage, startCheckupFromMainPage } from "./utils";
import SingleMonthSkeleton from "../SingleMonthSkeleton";
import "./SingleMonth.scss";
import { isAfter } from "date-fns";
import useCalendarStore from "../../stores/useCalendarStore";
import useUserParamsStore from "../../stores/useUserParamsStore";

const SingleMonth = ({ propsDate = new Date(), apiToken }) => {
  const [date] = useState(propsDate);
  const datesWithCheckup = useCalendarStore((state) => state.datesWithCheckup);
  const datesWithCheckupLoadingStatus = useCalendarStore(
    (state) => state.datesWithCheckupLoadingStatus
  );
  const setScrollPosition = useCalendarStore(
    (state) => state.setScrollPosition
  );
  const { language, shareToken, viewedByAdmin, gender } = useUserParamsStore();
  const { activeProperties } = useSelector((state) => state.sharingData);

  const handleScroll = () => {
    TELEGRAM.HapticFeedback.impactOccurred("light");
    const value = document.documentElement.scrollTop;
    setScrollPosition(value);
    sendMetrics("reachGoal", "click-date-btn");
  };

  const monthData = getMonthData(date?.getFullYear(), date?.getMonth());

  const getDayContent = (
    date,
    isToday,
    mood,
    hasNote,
    hasAIWisdom,
    hasWisdom
  ) => (
    <>
      <div
        className={cn("table-cell__date", {
          "table-cell__date_today": isToday,
        })}
        onClick={isToday ? startCheckupFromMainPage : undefined}
      >
        {date.getDate()}
      </div>
      {(!isAfter(date, new Date()) || mood) &&
        renderImage(
          mood,
          isToday,
          startCheckupFromMainPage,
          apiToken,
          shareToken
        )}
      <div className="table-cell__hasNote">
        {dayInfoHandler(hasNote, manyNotes, fewNotes, true)}
        {dayInfoHandler(hasAIWisdom, chatGPTIcon)}
        {dayInfoHandler(hasWisdom, wisdomIcon)}
      </div>
    </>
  );

  const renderDay = (date, index) => {
    if (!date) return <div key={index} className="empty-block" />;
    const dayInfo = areDatesEqualObj(date, datesWithCheckup);
    const isToday = areDatesEqual(date, new Date());
    return (
      <div className="table-cell" key={index}>
        {!isToday && activeProperties !== "default" ? (
          isAfter(date, new Date()) ? (
            <span className="table-cell-wrapper">
              {getDayContent(
                date,
                isToday,
                dayInfo?.mood,
                dayInfo?.notes_count,
                dayInfo?.has_ai_wisdom,
                dayInfo?.has_wisdom
              )}
            </span>
          ) : (
            <Link
              to={`/checkup/${getISODate(
                date
              )}?token=${apiToken}&language=${language}&gender=${gender}&viewed_by_admin=${viewedByAdmin}`}
              onClick={handleScroll}
              className="table-cell-wrapper"
            >
              {getDayContent(
                date,
                isToday,
                dayInfo?.mood,
                dayInfo?.notes_count,
                dayInfo?.has_ai_wisdom,
                dayInfo?.has_wisdom
              )}
            </Link>
          )
        ) : dayInfo?.mood ? (
          <Link
            to={`/checkup/${getISODate(
              date
            )}?token=${apiToken}&language=${language}&gender=${gender}&viewed_by_admin=${viewedByAdmin}`}
            onClick={() => {
              console.log("TUT");
              handleScroll();
            }}
            className="table-cell-wrapper"
          >
            <div className="table-cell-wrapper">
              {getDayContent(
                date,
                isToday,
                dayInfo?.mood,
                dayInfo?.notes_count,
                dayInfo?.has_ai_wisdom,
                dayInfo?.has_wisdom
              )}
            </div>
          </Link>
        ) : (
          <div className="table-cell-wrapper">
            {getDayContent(
              date,
              isToday,
              dayInfo?.mood,
              dayInfo?.notes_count,
              dayInfo?.has_ai_wisdom,
              dayInfo?.has_wisdom
            )}
          </div>
        )}
      </div>
    );
  };

  const setContent = (process) => {
    if (process === "loading") return <SingleMonthSkeleton />;
    if (process === "error")
      return <h5>{Translator.translate("LOADING_ERROR", language)}</h5>;
    return (
      <div className="single-month-wrapper">
        <div className="single-month">
          <div className="single-month__header">
            {Translator.translate(getLocalMonthNames(date), language)}{" "}
            {date.getFullYear()}
          </div>
          <div className="single-month-table">
            <div className="single-month-table__head">
              {WEEK_DAY_NAMES.map((name) => (
                <div className="single-month-table__head__week-days" key={name}>
                  {Translator.translate(name, language)}
                </div>
              ))}
            </div>
            <div className="single-month-table__body">
              {monthData.map((week, index) => (
                <div key={index} className="single-month-table__body__week">
                  {week.map((date, index) => renderDay(date, index))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return setContent(datesWithCheckupLoadingStatus);
};

export default SingleMonth;
