import CalendarLayout from "../../components/CalendarLayout";
import "./CalendarPage.scss";
import "../../resources/styles/skeleton.scss";
import ScrollButton from "../../UI/ScrollButton";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../UI/ErrorMessage/ErrorMessage";
import PageNavigation from "../../UI/PageNavigation";
import UserPanel from "../../UI/UserPanel/index";
import { useEffect } from "react";
import useCalendarStore from "../../stores/useCalendarStore";
import useUserParamsStore from "../../stores/useUserParamsStore";

const CalendarPage = () => {
  const setStartPage = useUserParamsStore((state) => state.setStartPage);
  const datesWithCheckupLoadingStatus = useCalendarStore(
    (state) => state.datesWithCheckupLoadingStatus
  );
  const { language, firstTimeOpen } = useUserParamsStore();

  useEffect(() => {
    if (firstTimeOpen) setStartPage("calendar");
  }, []);

  const setContent = (process, language = "ru") => {
    if (process === "error") {
      return <ErrorMessage />;
    }
    return (
      <div className="calendar-page">
        <UserPanel />
        <CalendarLayout />
        <PageNavigation />
        <ScrollButton />
      </div>
    );
  };

  return setContent(datesWithCheckupLoadingStatus, language);
};

export default CalendarPage;
