import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./components/App";
import store from "./store";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

// фикс резинового скролла, ломает бесконечный скролл календаря
// if (window.visualViewport) {
//   window.visualViewport.addEventListener("resize", () => {
//     document.body.style.height = window.visualViewport.height + "px";
//   });
// }
// window.addEventListener("scroll", () => {
//   if (window.scrollY > 0) window.scrollTo(0, 0);
// });

root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  //</React.StrictMode>
);
